import type {EditorSDK} from '@wix/platform-editor-sdk'
import {
  EVENTS_APP_ID,
  EVENT_DETAILS_SECTION_ID,
  EVENT_LIST_SECTION_ID,
  SCHEDULE_SECTION_ID,
} from '@wix/wix-events-commons-statics'

export const getEventsPages = async (editorSdk: EditorSDK, token: string) => {
  const [{applicationId}, allPages] = await Promise.all([
    editorSdk.tpa.app.getDataByAppDefId(token, EVENTS_APP_ID),
    editorSdk.pages.data.getAll(token),
  ])

  return allPages.filter(({tpaApplicationId}) => tpaApplicationId === applicationId)
}

export const getSectionPageRef = async (sectionId: string, editorSdk: EditorSDK, token: string) => {
  const eventsPages = await getEventsPages(editorSdk, token)
  const {id, type} = eventsPages.find(({tpaPageId}) => tpaPageId === sectionId)

  return {id, type}
}

export const ensurePagesManaged = async (editorSdk: EditorSDK, token: string, t: Function) => {
  const eventsPages = await getEventsPages(editorSdk, token)

  const managedPageData: {[key: string]: {title: string}} = {
    [EVENT_LIST_SECTION_ID]: {
      title: t('pagesPanelEventsListTitle'),
    },
    [EVENT_DETAILS_SECTION_ID]: {
      title: t('pagesPanelEventsDetailsTitle'),
    },
    [SCHEDULE_SECTION_ID]: {
      title: t('pagesPanelEventsScheduleTitle'),
    },
  }
  const managedPages = Object.keys(managedPageData)

  await Promise.all(
    eventsPages.map(async ({id, type, managingAppDefId: currentManagingAppDefId, tpaPageId}) => {
      if (currentManagingAppDefId !== EVENTS_APP_ID && managedPages.includes(tpaPageId)) {
        const data = {
          managingAppDefId: EVENTS_APP_ID,
          title: managedPageData[tpaPageId].title,
        }

        // @ts-expect-error
        await editorSdk.pages.data.update(token, {pageRef: {id, type}, data})
      }

      if ([EVENT_LIST_SECTION_ID, SCHEDULE_SECTION_ID].includes(tpaPageId)) {
        await editorSdk.document.pages.setState(token, {
          // @ts-expect-error
          state: {
            [tpaPageId]: [{id}],
          },
        })
      }
    }),
  )
}
