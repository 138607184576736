export type TFunction = (key: string) => string

const getTranslationUrl = (staticsUrl: string, locale: string) => {
  return `${staticsUrl}/assets/locales/editor-script_${locale}.json`
}

export const getTranslateFunction = async (staticsUrl: string, locale: string, httpClient): Promise<TFunction> => {
  const translationsJson = (await httpClient.get(getTranslationUrl(staticsUrl, locale))).data
  return keyToTranslate => {
    return translationsJson[keyToTranslate] || keyToTranslate
  }
}
