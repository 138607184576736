import {EditorSDK} from '@wix/platform-editor-sdk'
import {FlowApi} from '../types'

export type WithApproval = <T>(fn: () => T) => Promise<T>

export const createWithApproval = (editorSdk: EditorSDK, token: string, flowApi: FlowApi): WithApproval => {
  return async fn => {
    try {
      return editorSdk.document.transactions.runAndWaitForApproval(token, fn)
    } catch (error) {
      const isTransactionError = await editorSdk.document.transactions.isConflictError(token, error)

      if (isTransactionError) {
        try {
          return editorSdk.document.transactions.runAndWaitForApproval(token, fn)
        } catch (secondError) {
          flowApi.reportError(secondError)
        }
      } else {
        flowApi.reportError(error)
      }

      return null
    }
  }
}
