import {TAB} from '../../../../commons/constants/navigation'

export const BASE_PATH = '/'

export const ARTICLES = {
  [TAB.MAIN]: '7851c34b-228f-47f4-a7ed-eea90a9139c4',
  [TAB.MY_EVENTS]: 'c646af30-ffc4-4c6c-bcdd-73d36f045f55',
  [TAB.LAYOUT]: 'd6a87d2d-4a70-4ac7-b149-cd4816247043',
  [TAB.DISPLAY]: '53b3220c-b79d-479d-9d45-518a0d482bb1',
  [TAB.TEXT]: '253dcb0e-e996-4359-9b43-c0230e644dcd',
  [TAB.DESIGN]: '7103f19e-96e4-43dc-adec-27c5fcfc6363',
  [TAB.SUPPORT]: '8c91e98b-14f5-4616-9b5d-4d426b61e1ee',
  [TAB.MEMBERS]: '1679255a-3e6f-4480-a81e-2c06c37efa9e',
}
